<template>
  <b-modal
    id="leave-page-entitlement-modal"
    :title="$t('ENTITLEMENT.DETAILS')"
    title-class="h4 font-weight-bold"
    lazy
    size="lg"
    hide-footer
  >
    <div class="d-flex flex-column p-5">
      <CompanyLeaveDetails
        class="mb-2"
        :leave="assignedLeave.leave"
        :is-busy="isBusy"
      ></CompanyLeaveDetails>

      <IndividualEmployeeLeaveEntitlementForm
        :initial-form="assignedLeave"
        @onSubmitClick="submitForm"
      ></IndividualEmployeeLeaveEntitlementForm>
    </div>
  </b-modal>
</template>

<script>
import CompanyLeaveDetails from "@/modules/company/components/shared/CompanyLeaveDetails";
import IndividualEmployeeLeaveEntitlementForm from "@/modules/school/components/management/leave/entitlement/IndividualEmployeeLeaveEntitlementForm";

export default {
  name: "StaffIndividualLeaveEntitlementModal",
  components: { IndividualEmployeeLeaveEntitlementForm, CompanyLeaveDetails },
  props: {
    assignedLeave: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  methods: {
    /**
     * Submit the form.
     *
     * @param form
     */
    submitForm(form) {
      console.log("Form ", form);

      this.$store
        .dispatch("submitStaffIndividualEntitlementForm", {
          form: form,
        })
        .then(() => {
          const successMsg = `Successfully updated Teacher entitlement`;
          this.$bvModal.msgBoxOk(successMsg, {
            title: "Success",
            centered: true,
          });
        });

      this.$emit("formSubmitted", form);
    },
  },
};
</script>

<style scoped></style>
