<template>
  <AppPageCard>
    <template #card-title> {{ `Staff ${$t("LEAVE.ENTITLE")}` }} </template>

    <div>
      <span
        class="
          text-muted
          font-weight-bold font-size-base
          d-none d-md-inline
          mr-1
        "
      >
        {{ $t("EMPLOYEE.EMP") }}
      </span>
      <span
        class="
          text-dark-50
          font-weight-bolder font-size-base
          d-none d-md-inline
          mr-3
        "
      >
        {{ getTitle() }}
      </span>
      <IndividualEmployeeLeaveEntitlementTable
        :is-busy="isBusy"
        :items="items"
        :pagination="pagination"
        @onClickEntitlement="showModal"
        @row-clicked="showModal"
        @onPageChange="changePage"
      ></IndividualEmployeeLeaveEntitlementTable>

      <StaffIndividualLeaveEntitlementModal
        :assigned-leave="form"
        :is-busy="isBusy"
        @formSubmitted="fetch"
      ></StaffIndividualLeaveEntitlementModal>
    </div>
  </AppPageCard>
</template>

<script>
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import IndividualEmployeeLeaveEntitlementTable from "@/modules/school/components/management/leave/entitlement/IndividualEmployeeLeaveEntitlementTable";
import { mapGetters } from "vuex";
import StaffIndividualLeaveEntitlementModal from "@/modules/school/components/management/leave/entitlement/staff/StaffIndividualLeaveEntitlementModal";

export default {
  name: "StaffIndividualLeaveEntitlementPage",
  components: {
    StaffIndividualLeaveEntitlementModal,
    IndividualEmployeeLeaveEntitlementTable,
    AppPageCard,
  },
  data() {
    return {
      isBusy: false,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    getTitle() {
      return this.$route.query.name;
    },

    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchStaffIndividualEntitlementList", {
          id: this.$route.query.id,
        })
        .finally(() => (this.isBusy = false));
    },

    changePage(page) {
      console.log(page);
      this.$store.commit("setStaffIndividualEntitlementListPage", page);
      this.fetch();
    },

    /**
     *
     * @param data
     */
    showModal(data) {
      console.log("Data ", data);

      this.isBusy = true;
      this.$store
        .dispatch("fetchStaffIndividualEntitlementForm", {
          id: data.id,
        })
        .finally(() => (this.isBusy = false));

      this.$bvModal.show("leave-page-entitlement-modal");
    },
  },
  computed: {
    ...mapGetters({
      items: "getStaffIndividualEntitlementList",
      pagination: "getStaffIndividualEntitlementListPagination",
      form: "getStaffIndividualEntitlementForm",
    }),
  },
};
</script>

<style scoped></style>
