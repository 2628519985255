// Calculate Balance from days
const calculateBalance = (days, previousDays, balance, previousBalance) => {
  console.log(days, previousDays, balance, previousBalance);

  let calculatedBalance = previousBalance;
  if (days !== previousDays) {
    try {
      const diff = Math.abs(days - previousDays);
      console.log("Difference is", diff);

      if (days > previousDays) {
        console.log("Days are larger than holding days");
        calculatedBalance = previousBalance + diff;
      } else if (days === previousDays) {
        console.log("Days similar to holding days");
        calculatedBalance = previousBalance;
      } else if (days < previousDays) {
        console.log("Less", days, diff);
        calculatedBalance = previousBalance - diff;
        console.log("Balance now", balance);
      }
    } catch (e) {
      console.error(e);
    }
  }

  return calculatedBalance;
};

export default calculateBalance;
